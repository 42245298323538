<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                开源！非凸 Rust 高性能日志库 ftlog
              </p>
              <div class="fst-italic mb-2">2022年11月1日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >开源项目</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/17/01-首图.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    日志是应用程序的重要组成部分，无论是服务端程序还是客户端程序，都需要日志作为错误输出或业务记录。非凸Rust高性能日志库ftlog，支持“受限写入”以及“时间切分”等功能，具备显著的性能优势。
                  </p>
                  <h4 class="my-4">一、ftlog与普通日志，有何不同？</h4>
                   <p>
                    普通日志库受到磁盘IO和系统Pipe的影响，单线程顺序写入单条速度大概要2500ns（SSD），如果碰到IO抖动或者慢磁盘，日志会是低延迟交易的主要瓶颈。而非凸科技开源的Rust高性能日志库ftlog v0.1.0，先把日志Send到Channel，再启动后台单独线程Recv，且磁盘写入，测试速度在300ns左右。
                  </p>
                  <p>
                    值得注意的是，ftlog与Rust的log生态不兼容，建议删除掉原来的日志库。特别是不要让两个日志库导出到同一个地方，否则两个日志生态会同时打印，导致日志不可读。
                  </p>
                  <p>
                    低延迟交易，要求整个交易链条上的所有环节，都尽量缩短时间间隔。而非凸日志库ftlog在速度上更快，可实现更低的延迟。
                  </p>
                  <p>
                    过去，金融证券领域的高性能一般指的是高吞吐量，满足一定的延迟要求。但随着机构交易占比的逐渐提升，他们对延迟的要求也越来越高。对券商来说，谁能在延迟上取得最低，谁就可以掌握客户。
                  </p>
                  <h4 class="my-4">二、在开源上，不断发力</h4>
                  <p>
                    Rust作为一门备受关注的新编程语言，它在保证内存安全的同时，还能实现不亚于C/C++等语言的性能。非凸科技选择Rust，最主要的原因是Rust的高性能、高安全和可靠性与低延迟交易的核心需求高度匹配。在引擎层面，Rust具有可靠的安全性和稳定性；在数据接入层面，Rust足够底层，可以做到更多其他语言难以做到的事。
                  </p>
                  <p>
                    此外，对于企业级项目的开发，采用Rust更能保证程序的稳定性。对于高并发和低延迟部分，Rust相关的成熟库还不多，需要企业级的打磨才能更可靠。对此，非凸科技将在开源项目上不断发力，攻坚更多核心技术。
                  </p>
                  <p>
                    做真正自研的技术，尤其是基础软件和核心底层技术。非凸科技希望通过开源，与广大开发者、行业伙伴、客户，携手推进Rust在低延迟交易领域的开发、应用和落地，加速推动国产基础软件的繁荣和发展。
                  </p>
                  <p>
                    非凸科技计划在ftlog v0.2.0版本迁回官方log，并加入target支持，欢迎大家的建议、交流和探讨。
                  </p>
                  <p>
                    <a href="https://github.com/nonconvextech/ftlog" target="_blank">github 代码仓库</a>
                  </p>
                  <p>
                    <a href="https://crates.io/crates/ftlog" target="_blank">crates 代码仓库</a>
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/17/02-ftlog.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                 
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News13",
};
</script>

<style></style>
